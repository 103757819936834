import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="left">
        <div className="bg-img"></div>
        <div className="left-inner">
          <div className="info">
            <h1 className="name">William Peters</h1>
            <p>Desenvolvedor | Graduando em ADS</p>
            <div className="social-links">
              <a href="https://www.linkedin.com/in/william-peters-bb42861b0/" className="fa fa-linkedin" target="_blank" rel="noopener noreferrer"></a>
              <a href="https://github.com/Wuhpeters?tab=repositories" className="fa fa-github" target="_blank" rel="noopener noreferrer"></a>
              <a href="mailto: william@williampeters.com.br" className="fa fa-envelope"></a>
            </div>
          </div>
        </div>
      </div>

      <div className="right">
        <div className="right-inner">
          <section className="introduction">
            <h4>Sobre</h4>
            <div className="content">
              <p className="intro-p">
                Em 2019 descobri o mundo da tecnologia e desde então venho me aprimorando em programação back-end com JavaScript, Node e Java. Também encontrei um lado fascinante no front-end com HTML, CSS e React.
              </p>
              <p>
                No meu tempo livre, gosto de praticar exercícios, passear com minha família e cozinhar.
              </p>
            </div>
          </section>

          <section className="skills">
            <h4>Skills</h4>
            <div className="content">
              <ul className="description">
                <li>JavaScript, Java, Node.js, Postgrees</li>
                <li>HTML, CSS, React</li>
                <li>Git, GitHub</li>
              </ul>
            </div>
          </section>

          <section className="education">
            <h4>Educação</h4>
            <div className="content">
              <p className="university">Universidade Unisinos</p>
              <p className="major"><strong>Curso:</strong> Análise e Desenvolvimento de Sistemas</p>
              <p className="grad-date">Graduação prevista: 2025</p>
            </div>
          </section>

          <section className="experience">
            <h4>Experiência</h4>
            <div className="content">
              <div className="exp-item">
                <p className="title">Crescer lvl 1 - CWI.</p>
                <p className="project-desc">Apresenzado de linguagens como Java, JavaScript, Postgrees</p>
              </div>
            </div>
          </section>

          <section className="projects">
            <h4>Projetos</h4>
            <div className="content">
              <div className="project-item">
                <p className="project-title">Portfolio Pessoal</p>
                <p className="project-desc">Website criado usando React e CSS para mostrar projetos pessoais.</p>
              </div>
            </div>
          </section>

          <a href="#resume" className="resume">
          <a href="mailto: william@williampeters.com.br" className="resume-link">Entre em Contato</a>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
